import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { SessionSchema } from '@/types';
import type { GenericResponse } from '.';

type GetCurrentSessionResponse = GenericResponse<SessionSchema>;

export const getCurrentSession =
  async (): Promise<GetCurrentSessionResponse> => {
    const { response, data, error } = await get('/v1/sessions/current', {
      headers: getHeaders(),
    });

    return {
      status: response.status,
      error,
      data,
    };
  };
