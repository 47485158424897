export enum RouteNames {
  PAYMENTS = 'PAYMENTS',
  REFUND = 'REFUND',
  RATES = 'RATES',
  RATE = 'RATE',
  CREATE_RATE = 'CREATE_RATE',
  CUSTOMER_TYPES = 'CUSTOMER_TYPES',
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  CREATE_CUSTOMER_TYPE = 'CREATE_CUSTOMER_TYPE',
  PRODUCTS = 'PRODUCTS',
  PRODUCT = 'PRODUCT',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  VENDORS = 'VENDORS',
  VENDOR = 'VENDOR',
  CREATE_VENDOR = 'CREATE_VENDOR',
  HOTELS = 'HOTELS',
  HOTEL = 'HOTEL',
  CREATE_HOTEL = 'CREATE_HOTEL',
  LOCATIONS = 'LOCATIONS',
  LOCATION = 'LOCATION',
  CREATE_LOCATION = 'CREATE_LOCATION',
  SOURCES = 'SOURCES',
  SOURCE = 'SOURCE',
  CREATE_SOURCE = 'CREATE_SOURCE',
  DESKS = 'DESKS',
  DESK = 'DESK',
  CREATE_DESK = 'CREATE_DESK',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD',
  PAYMENT_TYPES = 'PAYMENT_TYPES',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  CREATE_PAYMENT_TYPE = 'CREATE_PAYMENT_TYPE',
  PAY_DEVICES = 'PAY_DEVICES',
  PAY_DEVICE = 'PAY_DEVICE',
  CREATE_PAY_DEVICE = 'CREATE_PAY_DEVICE',
  TAX_TYPES = 'TAX_TYPES',
  TAX_TYPE = 'TAX_TYPE',
  CREATE_TAX_TYPE = 'CREATE_TAX_TYPE',
  CANCELLATION_REASONS = 'CANCELLATION_REASONS',
  CANCELLATION_REASON = 'CANCELLATION_REASON',
  CREATE_CANCELLATION_REASON = 'CREATE_CANCELLATION_REASON',
  USERS = 'USERS',
  USER = 'USER',
  CREATE_USER = 'CREATE_USER',
  CLIENTS = 'CLIENTS',
  CLIENT = 'CLIENT',
  CREATE_CLIENT = 'CREATE_CLIENT',
  EDIT_CLIENT = 'EDIT_CLIENT',
  CREATE_VOUCHER = 'CREATE_VOUCHER',
  VOUCHER = 'VOUCHER',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  COMPANY_SETTINGS_INFORMATION = 'COMPANY_SETTINGS_INFORMATION',
  COMPANY_SETTINGS_BANK_AND_PAYMENTS = 'COMPANY_SETTINGS_BANK_AND_PAYMENTS',
  REPORTS = 'REPORTS',
  REPORTS_AGENT_CLOSEOUT = 'REPORTS_AGENT_CLOSEOUT',
  REPORTS_AGENT_COMMISSION = 'REPORTS_AGENT_COMMISSION',
  REPORTS_AFFILIATE_COMMISSION = 'REPORTS_AFFILIATE_COMMISSION',
  REPORTS_AR_AP = 'REPORTS_AR_AP',
  REPORTS_CASH_RECEIPTS = 'REPORTS_CASH_RECEIPTS',
  REPORTS_SALES_ANALYSIS = 'REPORTS_SALES_ANALYSIS',
  ACTIVITY_LOG = 'ACTIVITY_LOG',
  VENDOR_SEARCH = 'VENDOR_SEARCH',
  AGENT_COMMISSION_POLICIES = 'AGENT_COMMISSION_POLICIES',
  AGENT_COMMISSION_POLICY = 'AGENT_COMMISSION_POLICY',
  CREATE_AGENT_COMMISSION_POLICY = 'CREATE_AGENT_COMMISSION_POLICY',
  AFFILIATES = 'AFFILIATES',
  AFFILIATE = 'AFFILIATE',
  CREATE_AFFILIATE = 'CREATE_AFFILIATE',
  DESK_SELECT = 'DESK_SELECT',
  COMPANY = 'COMPANY',
  COMPANY_DASHBOARD = 'COMPANY_DASHBOARD',
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  VERSION = 'VERSION',
  NO_ACCESS = 'NO_ACCESS',
}
