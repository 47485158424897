import { defineStore } from 'pinia';
import { Features } from '@/common/Features';

type FeaturesType = keyof typeof Features;

type FeatureFlags = Partial<Record<FeaturesType, boolean>>;

interface FeatureFlagStoreState {
  featureFlags: FeatureFlags;
}

export const useFeatureFlagStore = defineStore('featureFlagStore', {
  state: (): FeatureFlagStoreState => ({
    featureFlags: {},
  }),
  actions: {
    loadFlags() {
      const storedFlags = sessionStorage.getItem('featureFlags');
      if (storedFlags) {
        const parsedFlags = JSON.parse(storedFlags);
        Object.keys(parsedFlags).forEach((flag) => {
          if (flag in Features) {
            this.featureFlags[flag as FeaturesType] = parsedFlags[flag];
          }
        });
      }
    },

    isEnabled(flag: FeaturesType): boolean {
      return !!this.featureFlags[flag];
    },
  },
});
