import mixpanel from 'mixpanel-browser';
import { Logger } from '@/common/Logger';
import { isMixpanelEnabledOnCurrentEnvironment } from '@/common/mixpanel/isMixpanelEnabledOnCurrentEnvironment';
import { config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';
import type { MixpanelEvent } from '@/types/MixpanelEvent';
import { useCurrentSession } from './useCurrentSession';

export const useMixpanel = () => {
  const { loadCurrentSession } = useCurrentSession();

  const identifyUser = async () => {
    const authStore = useAuthStore();

    await loadCurrentSession();
    if (!authStore.session) {
      Logger.info('Missing session. Failed to identify user.');
      return;
    }
    try {
      mixpanel.identify(authStore.session.user.id);
    } catch (error: unknown) {
      if (isMixpanelEnabledOnCurrentEnvironment) {
        Logger.error(
          'Attempting to identify user before Mixpanel initialization',
        );
      }
    }
  };

  const initializeMixpanel = () => {
    const authStore = useAuthStore();

    if (!isMixpanelEnabledOnCurrentEnvironment) {
      return;
    }

    if (!config.MIXPANEL_TOKEN) {
      Logger.error(
        `Missing MIXPANEL_TOKEN on environment ${config.ENVIRONMENT}`,
      );
      return;
    }

    mixpanel.init(config.MIXPANEL_TOKEN, {
      track_pageview: true,
      persistence: 'localStorage',
      record_sessions_percent: 50,
    });

    if (authStore.isLoggedIn) {
      identifyUser();
    }
  };

  const trackEvent = (
    event: MixpanelEvent,
    eventProperties: Record<string, any> = {},
  ): void => {
    try {
      mixpanel.track(event, eventProperties);
    } catch (error: unknown) {
      if (isMixpanelEnabledOnCurrentEnvironment) {
        Logger.error(
          'Attempting to track event before Mixpanel initialization',
        );
      }
    }
  };

  return { identifyUser, initializeMixpanel, trackEvent };
};
