import { createApp } from 'vue';
import '@fareharbor-com/beacon-vue/fonts/roboto';
import {
  useTheme,
  dashboardLightTheme,
} from '@fareharbor-com/beacon-vue/themes';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import { getApolloClient } from '@/common/getApolloClient';
import { initializeSentry } from '@/common/sentry/initializeSentry';
import { useFeatureFlagStore } from '@/stores/useFeatureFlagStore';
import App from './App.vue';
import './assets/main.css';
import './assets/print.css';
import { useMixpanel } from './composables/useMixpanel';
import { router } from './router';
import { setGuardForRouter } from './router/guard';
import { setRouteErrorHandler } from './router/setRouteErrorHandler';

useTheme(dashboardLightTheme);

const pinia = createPinia();

setGuardForRouter(router);
setRouteErrorHandler(router);

const app = createApp(App);
const { initializeMixpanel } = useMixpanel();

// Sentry should be initialize as soon as we have an app
// So we can catch errors as early as possible
initializeSentry(app);

app.use(pinia);
app.provide(DefaultApolloClient, getApolloClient());
app.use(router);

const featureFlagStore = useFeatureFlagStore();
featureFlagStore.loadFlags();

initializeMixpanel();

app.mount('#app');

document.title = 'Desk';
