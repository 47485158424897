import { getCurrentSession } from '@/common/api/getCurrentSession';
import { APIError } from '@/common/errors/APIError';
import { handleThrownError } from '@/common/errors/handleThrownError';
import { useAuthStore } from '@/stores/useAuthStore';

export const useCurrentSession = () => {
  const loadCurrentSession = async () => {
    try {
      const authStore = useAuthStore();
      const { status, data, error } = await getCurrentSession();

      if (status !== 200 || !data) {
        throw new APIError('Failed to load current session', { status }, error);
      }
      authStore.session = data;
    } catch (error: unknown) {
      handleThrownError({
        error,
        log: true,
        toastMessage: 'Failed to load user session',
      });
    }
  };
  return { loadCurrentSession };
};
