<script setup lang="ts">
import { computed } from 'vue';

interface DeskPageProps {
  fullWidth?: boolean;
}

const props = defineProps<DeskPageProps>();

const fullWidthClasses = 'w-full m-auto flex flex-col';
const defaultClasses =
  'w-full xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-lg m-auto px-6 flex flex-col';

const classes = computed(() =>
  props.fullWidth ? fullWidthClasses : defaultClasses,
);
</script>

<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>
